<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Farmer'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/farmer`" title="Farmer" :columns="columns"
                routerpath="/master/account/addfarmer" :formOptions="formOptions" :edit="edit" :add="add"
                :canDelete="canDelete" :viewFields="viewFields" :newPage="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import { BRow, BCol } from "bootstrap-vue";
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
import TabPosition from "../../../../components/TabView.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;

export default {
  components: {
    BRow,
    BCol,
    GoodTableColumnSearch,
    TabPosition,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Farmer" : "Add Farmer"}`,
        submitRouterPath: "/master/account",
        usersDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/farmer`,
        getEditValue: `${baseApi}/getfarmerById`,
        inputFields: [

        ],
      },
      viewFields: [
        {
          label: 'Profile Image',
          field: 'profile_image',
          type: 'image',
          class: "col-md-12",
        },
        {
          label: 'Surname',
          field: 'surname',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Farmer Name',
          field: 'name',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Father/Husband Name',
          field: 'fathername',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Mobile No',
          field: 'mobile',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Email',
          field: 'email',
          type: 'email',
          class: "col-md-6",
        },
        {
          label: 'Address 1',
          field: 'addressline1',
          type: 'text',
          class: "col-md-12",
        },
        {
          label: 'Address 2',
          field: 'addressline2',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Address 3',
          field: 'addressline3',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Pincode',
          field: 'pincode',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'City',
          field: 'pertaluka',
          type: 'text',
          class: "col-md-4",
        }, {
          label: 'Area',
          field: 'area',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'State',
          field: 'state',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Gender',
          field: 'gender',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Aadhar Card No',
          field: 'aadharcardno',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Pan No',
          field: 'panno',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Aadhar Card Front Side',
          field: 'aadharfrontside',
          type: 'attachment',
          class: "col-md-4",
        },
        {
          label: 'Aadhar Card Back Side',
          field: 'aadharbackside',
          type: 'attachment',
          class: "col-md-4",
        },
        {
          label: 'Pan Card',
          field: 'pancard',
          type: 'attachment',
          class: "col-md-4",
        },
        {
          field: 'FarmerDocuments',
          tableHead: ['DOCUMENT NAME', 'DOCUMENT ATTACHMENT', 'REMARK',],
          tableField: [
            {
              type: 'text',
              field: 'documentname',
            },
            {
              type: 'attachment',
              field: 'documentattached',
            },
            {
              type: 'text',
              field: 'remarks',
            },
          ],
          type: 'multiple',
          class: "col-md-12",
        },
      ],
      columns: [
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search  Name",
          },
        },
        {
          label: "Mobile No",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile No",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
        },
        {
          label: "City",
          field: "city",
          filterOptions: {
            enabled: true,
            placeholder: "Search City",
          },
        },
        {
          label: "State",
          field: "state",
          filterOptions: {
            enabled: true,
            placeholder: "Search State",
          },
        },

        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],

      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Farmer") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;

          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
